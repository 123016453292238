import { useEffect } from "react";
import Head from "next/head";
import styled from "styled-components";
import tw from "twin.macro";
import fs from "fs";

import {
  AnimateIn,
  Container,
  Banner,
  Text,
  PortfolioLink,
  ContainerBreak,
  IconText,
  LatestPosts,
  NavigationSpacer,
} from "@/components";
import { useMenu, MenuTheme } from "@/context";
import { generateRss, getAllPosts } from "@/util";
import designIcon from "@/icons/design-icon.json";
import developIcon from "@/icons/develop-icon.json";

export const getStaticProps = async () => {
  const posts = getAllPosts;
  const rss = generateRss(posts);

  fs.writeFileSync("./public/rss.xml", rss);

  return {
    props: {},
  };
};

export const StyledServices = styled.div`
  ${tw`pt-10 pb-16 bg-grey sm:-mt-20 sm:pt-32 lg:pt-40 lg:pb-20 `};

  @media (min-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 120px);
  }
`;

const Home = () => {
  const { setTheme } = useMenu();
  useEffect(() => {
    setTheme(MenuTheme.Pink);
  }, []);

  return (
    <>
      <Head>
        <title>Freelance Website Developer & Designer | Luke Brown</title>
        <meta
          name="description"
          content="Cardiff based website developer, serving clients and agencies of all shapes and sizes to provide online experiences that are a joy to use."
        />
      </Head>
      <NavigationSpacer />
      <Banner
        title={
          <>
            Your local, freelance
            <br className="hidden xs:block sm:hidden lg:block" />
            <span className=""> </span>
            website developer
          </>
        }
        body={
          <span className="block sm:max-w-none lg:max-w-lg">
            Over the past 4 years I have been dedicated to serving clients and
            agencies of all shapes and sizes to provide online experiences that
            are a joy to use.
          </span>
        }
        buttonOne={{
          href: "/services",
          text: "View Services",
          variant: "SECONDARY",
        }}
        buttonTwo={{
          href: "/work",
          text: "Recent Projects",
          variant: "CLEAR",
        }}
      />
      <AnimateIn>
        <Container>
          <div className="py-12 lg:py-16">
            <Text
              title={
                <h2>
                  I am a creative freelance web designer and developer serving
                  clients and agencies in and around Wales.
                </h2>
              }
            >
              I am passionate about making the internet a better place by
              building online experiences that are fast, performant, scalable
              and accessible to all. I collaborate closely with people like you
              to produce online experiences that are completely bespoke to suit
              your business needs.
            </Text>
          </div>
          <ContainerBreak>
            <PortfolioLink />
          </ContainerBreak>
        </Container>
        <StyledServices>
          <Container>
            <Text title={<h2>What can I help you with?</h2>}>
              I specialise in high quality website design and development that
              helps businesses achieve their goals online. Whether you are
              looking for an e-commerce store, brochure site or web application,
              I can help you bring it to life with visually stunning design and
              high performance development.
            </Text>
            <div className="pt-16">
              <IconText
                icon={developIcon}
                title="Website Development"
                button={{
                  text: "Learn more",
                  variant: "BLACK",
                  href: "/services/freelance-web-development",
                }}
              >
                I specialise in building modern, high performance, scalable
                websites and web applications. In order to maximise your
                potential to succeed online, I offer a wide range of development
                options to suit your business needs. Including a variety of
                content management systems such as Wordpress, CraftCMS and
                Sanity.
              </IconText>
            </div>
            <div className="pt-20">
              <IconText
                icon={designIcon}
                title="Website Design"
                button={{
                  text: "Learn more",
                  variant: "BLACK",
                  href: "/services/freelance-web-design",
                }}
              >
                Designing for the web is not only about ensuring your website
                looks good (although that is very important), it requires a
                detailed consideration for every user that visits your website.
                We take a collaborative approach to design, and always
                prioritise your users by focusing on user experience and
                accessibility from day one.
              </IconText>
            </div>
          </Container>
        </StyledServices>

        <LatestPosts count={3} />
      </AnimateIn>
    </>
  );
};

export default Home;
